import { Link } from 'gatsby';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import logo from 'src/assets/images/vector/spectrocoin-blog-logo.svg';
import LanguageSwitcher from 'src/components/languageSwitcher/LanguageSwitcher';
import getLocalizedRoute from 'src/helpers/getLocalizedRoute';
import fromUItoSpectroCoinLanding from 'src/helpers/spectroCoinLandingLocales';
import { HeaderProps } from '../header/Header';
import Search from '../search/Search';
import * as style from './HeaderDesktop.module.scss';

const HeaderDesktop: React.FC<Partial<HeaderProps>> = ({
	categories,
	currentCategory,
	navigationRoutes,
	allResults,
	setQuery,
	query,
	disableSearchResults,
}) => {
	const { locale } = useIntl();

	return (
		<div className={style.container}>
			<Link to={getLocalizedRoute('', locale)}>
				<FormattedMessage id="header.logo.alt" defaultMessage="SpectroCoin Blog logo">
					{(alt: string) => <img src={logo} alt={alt} className={style.logo} />}
				</FormattedMessage>
			</Link>
			<div className={style.options}>
				{currentCategory &&
					categories?.map((c) => (
						<Link
							to={getLocalizedRoute(c.path, locale)}
							className={c.name === currentCategory.name ? style.highlight : style.categoryLink}
							key={`${locale}/${c.name}`}
						>
							{c.name}
						</Link>
					))}
				<a
					href={`https://www.spectrocoin.com/${fromUItoSpectroCoinLanding(locale)}`}
					className={style.highlight}
				>
					<FormattedMessage id="header.landingLink" defaultMessage="Go to SpectroCoin" />
				</a>
				{allResults && (
					<Search
						allResults={allResults}
						setQuery={setQuery}
						query={query}
						disableSearchResults={disableSearchResults}
					/>
				)}
				<LanguageSwitcher navigationRoutes={navigationRoutes} />
			</div>
		</div>
	);
};

export default HeaderDesktop;
