import { Link } from 'gatsby';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import getLocalizedRoute from 'src/helpers/getLocalizedRoute';
import Highlighter from 'react-highlight-words';
import magnifyingGlass from 'src/assets/images/vector/magnifying-glass.svg';
import showAllTranslations from 'src/helpers/showAllTranslations';
import * as style from './SearchResults.module.scss';

interface Props {
	query: string;
	allResults: Result[];
}

export interface Result {
	title: string;
	content: string;
	path: string;
	excerpt: string;
	hidden?: boolean;
}

interface QueryResult extends Result {
	matches: number;
}

const SearchResults: React.FC<Props> = ({ query, allResults }) => {
	const { locale } = useIntl();

	// Search based on individual words the user put in
	const queryWords = query
		.split(' ')
		.filter((w) => w)
		.map((w) => w.toLowerCase());

	const queryPhrase = [query.toLowerCase()];

	// Count matches and determine the order of results
	// The more matches a result has, the higher in the list it is
	const results: QueryResult[] = allResults
		.map((r) => {
			let matches = 0;
			const titleToTest = r.title.toLowerCase();
			queryWords.forEach((word) => {
				matches += titleToTest.split(word).length - 1;
			});

			return {
				...r,
				matches,
			};
		})
		.filter((r) => r.matches > 0)
		.sort((a, b) => b.matches - a.matches);

	const contentResults: QueryResult[] = allResults
		.map((r) => {
			let matches = 0;
			const contentToTest = r.content.toLowerCase();
			const excerptToTest = r.excerpt.toLowerCase();

			queryPhrase.forEach((phrase) => {
				matches += contentToTest.split(phrase).length - 1;
				matches += excerptToTest.split(phrase).length - 1;
			});

			return {
				...r,
				matches,
			};
		})
		.filter((r) => r.matches > 0)
		.sort((a, b) => b.matches - a.matches);

	const handleUrl = () => {
		if (locale === 'en') {
			return `/${showAllTranslations(locale)}`;
		}
		return `/${locale}/${showAllTranslations(locale)}`;
	};

	return (
		results && (
			<div className={style.wrapper}>
				<div className={style.container}>
					{results.map((r) => (
						<Link to={getLocalizedRoute(r.path, locale)} key={r.title} className={style.result}>
							<Highlighter
								searchWords={queryWords}
								textToHighlight={r.title}
								highlightClassName={style.highlight}
								autoEscape={true}
							/>
						</Link>
					))}

					{contentResults.length > 0 ? (
						<div>
							<Link to={handleUrl()} state={{ inputFeed: query }} className={style.showAllLink}>
								<div className={style.showAll}>
									<div className={style.image}>
										<img src={magnifyingGlass} alt="magnifying glass" className="icon" />
									</div>
									<div className={style.text}>
										<FormattedMessage id="search.showAll" defaultMessage="Show all results" />
									</div>
								</div>
							</Link>
						</div>
					) : null}
				</div>
			</div>
		)
	);
};

export default SearchResults;
