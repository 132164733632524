import languageData from '../languages';

const getLocalizedRoute = (path: string, locale: string): string => {
	if (locale === languageData.Default.locale) {
		if (!path) return '/';
		return `/${path}/`;
	}

	if (!path) return `/${locale}/`;
	return `/${locale}/${path}/`;
};

export default getLocalizedRoute;
