import React, { useState } from 'react';
import magnifyingGlass from 'src/assets/images/vector/magnifying-glass.svg';
import close from 'src/assets/images/vector/close.svg';
import { defineMessages, useIntl } from 'react-intl';
import * as style from './Search.module.scss';
import SearchResults, { Result } from './SearchResults';

interface Props {
	allResults: Result[];
	setQuery: React.Dispatch<React.SetStateAction<string>>;
	query: string;
	disableSearchResults: boolean;
}

const translations = defineMessages({
	placeholder: {
		id: 'search.placeholder',
		defaultMessage: 'Search SpectroCoin Blog',
	},
});

const Search: React.FC<Props> = ({ allResults, setQuery, query, disableSearchResults }) => {
	const { formatMessage } = useIntl();

	const initialPanelState = () => {
		if (disableSearchResults) return true;
		return false;
	};
	const [showPanel, setShowPanel] = useState<boolean>(initialPanelState());

	return (
		<>
			<button type="button" className={style.searchButton} onClick={() => setShowPanel(true)}>
				<img src={magnifyingGlass} />
			</button>
			{showPanel &&
				(disableSearchResults ? (
					<div className={style.fixedPanel}>
						<img src={magnifyingGlass} />
						<input
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							placeholder={formatMessage(translations.placeholder)}
							onChange={(e) => setQuery(e.target.value)}
							className={style.inputField}
							value={query || ''}
						/>
						<button
							type="button"
							className={style.close}
							onClick={() => {
								setShowPanel(false);
								setQuery('');
							}}
						>
							<img src={close} />
						</button>
					</div>
				) : (
					<div className={style.panel}>
						<img src={magnifyingGlass} />
						<input
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							placeholder={formatMessage(translations.placeholder)}
							onChange={(e) => setQuery(e.target.value)}
							className={style.inputField}
						/>
						<button
							type="button"
							className={style.close}
							onClick={() => {
								setShowPanel(false);
								setQuery('');
							}}
						>
							<img src={close} />
						</button>
					</div>
				))}
			{!disableSearchResults ? query && <SearchResults query={query} allResults={allResults} /> : null}
		</>
	);
};

export default Search;
