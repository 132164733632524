import React from 'react';
import Category from 'src/interfaces/Category';
import HeaderDesktop from 'src/components/headerDesktop/HeaderDesktop';
import HeaderMobile from 'src/components/headerMobile/HeaderMobile';
import * as style from './Header.module.scss';
import { Result } from '../search/SearchResults';

export interface HeaderProps {
	categories: Category[];
	currentCategory: Category;
	navigationRoutes: {
		locale: string;
		route: string;
	}[];
	allResults: Result[];
	setQuery: any;
	query: any;
	disableSearchResults?: any;
}

const Header: React.FC<Partial<HeaderProps>> = (props) => {
	return (
		<>
			<div className={style.showAboveTablet}>
				<HeaderDesktop {...props} />
			</div>
			<div className={style.showBelowTablet}>
				<HeaderMobile {...props} />
			</div>
		</>
	);
};

export default Header;
