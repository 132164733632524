/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-confusing-arrow */
// Adapted from https://gitlab.spectrofinance.lt/spectrocoin/web/spectrocoin-landing-ui/-/tree/develop/src/components/LanguageSwitcher

import { Link } from 'gatsby';
import React, { RefObject, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import arrowUp from 'src/assets/images/vector/arrow-up.svg';
import getLocalizedRoute from 'src/helpers/getLocalizedRoute';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import languageData, { LocaleData } from 'src/languages';
import * as style from './LanguageSwitcher.module.scss';

interface SelectOption {
	label: string;
	value: string;
	locale: string;
	fullRoute: string;
}

interface Props {
	navigationRoutes?: {
		locale: string;
		route: string;
	}[];
}

const LanguageSwitcher: React.FC<Props> = ({ navigationRoutes }) => {
	const { locale } = useIntl();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const containerRef = useRef() as RefObject<HTMLDivElement>;

	useOnClickOutside(containerRef, () => setIsMenuOpen(false));

	// Create all of the options which can be selected
	const options: SelectOption[] = [];

	if (navigationRoutes) {
		navigationRoutes.forEach((nr) => {
			let language: LocaleData | undefined;
			Object.keys(languageData).forEach((key) => {
				if (languageData[key]?.locale === nr.locale) {
					language = languageData[key];
				}
			});

			if (!language) {
				return;
			}

			options.push({
				label: language.title,
				locale: language.locale,
				value: language.shortTitle,
				fullRoute: getLocalizedRoute(nr.route, language.locale),
			});
		});
	} else {
		Object.keys(languageData).forEach((key) => {
			const language = languageData[key];
			if (!language) return;

			options.push({
				label: language.title,
				locale: language.locale,
				value: language.shortTitle,
				fullRoute: getLocalizedRoute('', language.locale),
			});
		});
	}

	const currentOption = options.find((o) => o.locale === locale);
	const displayOptions = options
		.filter((o) => o.locale !== locale)
		.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
	const [selectedOption] = useState<SelectOption>(currentOption || displayOptions[0]);

	return (
		<div ref={containerRef} className={style.container}>
			<span className={style.selectedValue} onClick={() => setIsMenuOpen(!isMenuOpen)}>
				{selectedOption.value.toUpperCase()}
				<img src={arrowUp} className={isMenuOpen ? style.arrow : style.flippedArrow} />
			</span>
			<div className={isMenuOpen ? style.visibleListWrapper : style.listWrapper}>
				<ul className={style.list}>
					{displayOptions.map((option: SelectOption) => (
						<li key={option.value} className={style.listItem}>
							<Link to={option.fullRoute}>{option.label}</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default LanguageSwitcher;
