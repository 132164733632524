import { Link } from 'gatsby';
import React, { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import logo from 'src/assets/images/vector/spectrocoin-blog-logo.svg';
import arrowUp from 'src/assets/images/vector/arrow-up.svg';
import burger from 'src/assets/images/vector/burger.svg';
import close from 'src/assets/images/vector/close.svg';
import { HeaderProps } from 'src/components/header/Header';
import getLocalizedRoute from 'src/helpers/getLocalizedRoute';
import fromUItoSpectroCoinLanding from 'src/helpers/spectroCoinLandingLocales';
import socials from 'src/helpers/socials';
import * as style from './HeaderMobile.module.scss';
import LanguageSwitcher from '../languageSwitcher/LanguageSwitcher';
import Search from '../search/Search';

const HeaderMobile: React.FC<Partial<HeaderProps>> = ({
	categories,
	currentCategory,
	navigationRoutes,
	allResults,
	setQuery,
	query,
	disableSearchResults,
}) => {
	const categoryDropdownRef = useRef(null);
	const menuRef = useRef(null);
	const { locale } = useIntl();
	const [showCategories, setShowCategories] = useState<boolean>(false);
	const [showMenu, setShowMenu] = useState<boolean>(false);

	const handleCategoryClick = () => {
		if (categoryDropdownRef.current) {
			if (showCategories) enableBodyScroll(categoryDropdownRef.current);
			else disableBodyScroll(categoryDropdownRef.current);
		}
		setShowCategories(!showCategories);
	};

	const handleMenuClick = () => {
		if (menuRef.current && !showCategories) {
			if (showMenu) enableBodyScroll(menuRef.current);
			else disableBodyScroll(menuRef.current);
		}

		setShowMenu(!showMenu);
	};

	return (
		<div>
			<div className={style.container}>
				<Link to={getLocalizedRoute('', locale)}>
					<FormattedMessage id="header.logo.alt" defaultMessage="SpectroCoin Blog logo">
						{(alt: string) => <img src={logo} alt={alt} className={style.logo} />}
					</FormattedMessage>
				</Link>
				<div className={style.options}>
					{allResults && (
						<Search
							allResults={allResults}
							setQuery={setQuery}
							query={query}
							disableSearchResults={disableSearchResults}
						/>
					)}
					<button className={style.menuButton} type="button" onClick={() => handleMenuClick()}>
						<img src={showMenu ? close : burger} />
					</button>
				</div>
			</div>
			<div ref={menuRef}>
				{showMenu && (
					<div className={style.menuPanel}>
						<div>
							<div className={style.highlight}>
								<a href={`https://www.spectrocoin.com/${fromUItoSpectroCoinLanding(locale)}`}>
									<FormattedMessage id="header.landingLink" defaultMessage="Go to SpectroCoin" />
								</a>
							</div>
							<LanguageSwitcher navigationRoutes={navigationRoutes} />
						</div>
						<div className={style.socials}>
							<FormattedMessage id="footer.follow" defaultMessage="Follow us on social media" />
							<div>
								{socials.map((social) => {
									return (
										<a
											href={social.link}
											key={social.link}
											target="_blank"
											rel="noopener noreferrer"
										>
											<FormattedMessage {...social.alt}>
												{(alt: string) => <img src={social.image} alt={alt} />}
											</FormattedMessage>
										</a>
									);
								})}
							</div>
						</div>
					</div>
				)}
			</div>
			{categories && currentCategory && (
				<>
					<button className={style.categoriesButton} type="button" onClick={() => handleCategoryClick()}>
						<FormattedMessage id="header.categories" defaultMessage="Categories" />
						<img src={arrowUp} className={showCategories ? style.arrow : style.flippedArrow} />
					</button>
					<div ref={categoryDropdownRef}>
						{showCategories && (
							<div className={style.categories}>
								{categories.map((c) => {
									return (
										<div
											key={`${c.locale}/${c.name}`}
											className={
												c.name === currentCategory.name ? style.highlight : style.categoryLink
											}
										>
											<Link to={getLocalizedRoute(c.path, locale)}>{c.name}</Link>
										</div>
									);
								})}
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default HeaderMobile;
