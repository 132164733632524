// extracted by mini-css-extract-plugin
export var arrow = "HeaderMobile-module--arrow--HSHys";
export var categories = "HeaderMobile-module--categories--B9hp3";
export var categoriesButton = "HeaderMobile-module--categoriesButton--QoA0S";
export var categoryLink = "HeaderMobile-module--categoryLink--KlJ-Y";
export var container = "HeaderMobile-module--container--07scG";
export var flippedArrow = "HeaderMobile-module--flippedArrow--lq8DD";
export var highlight = "HeaderMobile-module--highlight--KNJDW";
export var logo = "HeaderMobile-module--logo--PtUJ5";
export var menuButton = "HeaderMobile-module--menuButton--Fx1me";
export var menuPanel = "HeaderMobile-module--menuPanel---M6II";
export var options = "HeaderMobile-module--options--Mpfh-";
export var socials = "HeaderMobile-module--socials--sOcjP";