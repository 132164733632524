import Category from '../interfaces/Category';
import languageData, { Languages } from '../languages';

const nameTranslations: Languages<string> = {
	Default: 'show-all-results',
	Arabic: 'عرض-كل-النتائج',
	ChineseMainland: '显示全部',
	ChineseTaiwan: '顯示全部',
	Dutch: 'toon-alle-resultaten',
	Italian: 'mostra-tutti-i-risultati',
	Japanese: 'すべての結果を表示',
	Korean: '모든 결과 표시',
	Latvian: 'rādīt-visus-rezultātus',
	Lithuanian: 'rodyti-visus-rezultatus',
	Polish: 'pokaż-wszystkie-wyniki',
	PortugueseBrazilian: 'mostrar-todos-os-resultados',
	PortugueseEuropean: 'mostrar-todos-os-resultados',
	Russian: 'показать-все-результаты',
	Spanish: 'mostrar-todos-los-resultados',
	French: 'afficher-tout-les-résultats',
	Turkish: 'showallTr', // Change when there's a translator
};

const showAllTranslations: (locale: string) => Category = (locale) => {
	let name = nameTranslations.Default;

	Object.keys(languageData).forEach((language) => {
		if (languageData[language]?.locale === locale) {
			const nameTranslation = nameTranslations[language];

			if (nameTranslation) name = nameTranslation;
		}
	});

	return name;
};

export default showAllTranslations;
